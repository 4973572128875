var enabledMap = {};

export function enableSubmitButton(caller, enabled) {
    var allowSubmit = true;

    enabledMap[caller] = enabled;
    for(var key in enabledMap) {
        if (enabledMap.hasOwnProperty(key)) {
            allowSubmit &= enabledMap[key];
        }
    }
    $('#account_submit').prop('disabled', !allowSubmit);
}
