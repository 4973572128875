const submit = require("registrations/submit.enabled")

$(function() {
    var country = $("#user_country");

    //Ensure that this is a field on this page!
    if(country.length) {
        // Turn the input into a boolean and enable the submitbutton!
        submit.enableSubmitButton('country', !!country.val());
    }

    country.on("keyup change input", function() {
        submit.enableSubmitButton('country', !!this.value);
    });
});
