const submit = require("registrations/submit.enabled")
const regex =  new RegExp(/^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]{2,30})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i);

$(function() {
    var email = $("#user_email").filter(":visible");
    var confirmation = $("#user_email_confirmation");
    var message = $('#email_error_message')
    var confirmationMessage = $('#confirm_email_error_message')
    var feedback = $('#confirm_email_feedback');

    if(email.length) {
        message.hide();
        submit.enableSubmitButton('email', false);
    }

    if(confirmation.length) {
        confirmationMessage.hide();
        submit.enableSubmitButton('confirm-email', false);
    }

    email
        .on("keyup blur", function() {
            var isValid = isEmalValid(this.value);

            submit.enableSubmitButton('email', isValid);
            if(isValid) {
                message.hide();
            } else {
                message.show();
            }
        })
        .on("keyup", function() {
            confirmation.trigger('keyup');
        });

    confirmation
        .on("keyup blur", function() {
            var isValid = isEmalValid(this.value) && this.value === email.val();

            submit.enableSubmitButton('confirm-email', isValid);
            feedback.removeClass("icon-ok success icon-remove danger");
            if(isValid) {
                feedback.addClass("icon-ok success");
            } else {
                feedback.addClass("icon-remove danger");
            }
        }).on("blur", function() {
            if(feedback.hasClass("success")) {
                confirmationMessage.hide();
            } else {
                confirmationMessage.show();
            }
        });
});

function isEmalValid(emailAddress) {
    return emailAddress.length > 0 && regex.test(emailAddress)

}
